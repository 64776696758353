body { background-color: #131326 }

@font-face {
  font-family: 'AnitaSemiSquare';
  src: local('AnitaSemiSquare'), url(./fonts/AnitaSemiSquare.ttf) format('truetype');
}

@font-face {
  font-family: 'vermin_vibes';
  src: local('vermin_vibes'), url(./fonts/vermin_vibes.ttf) format('truetype');
}

@font-face {
  font-family: 'VerminVerile';
  src: local('VerminVerile'), url(./fonts/VerminVerile.ttf) format('truetype');
}

@font-face {
  font-family: 'Dragonslapper';
  src: local('Dragonslapper'), url(./fonts/Dragonslapper.ttf) format('truetype');
}

@font-face {
  font-family: 'Technoma';
  src: local('Technoma'), url(./fonts/Technoma.ttf) format('truetype');
}